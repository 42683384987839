@font-face {
  font-family: homa;
  src: url(./Assets/fonts/homa.ttf) format("truetype");
}

@font-face {
  font-family: yekan;
  src: url(./Assets/fonts/yekan.ttf) format("truetype");
}

@font-face {
  font-family: dastnevis;
  src: url(./Assets/fonts/dastnevis.ttf) format("truetype");
}

@font-face {
  font-family: vazir;
  src: url(./Assets/fonts/vazir-fd.ttf) format("truetype");
}

@font-face {
  font-family: iransans;
  src: url(./Assets/fonts/woff/IRANSansX-Regular.woff) format("woff");
  src: url(./Assets/fonts/woff2/IRANSansX-Regular.woff2) format("woff2");
}

@font-face {
  font-family: material-icons;
  src: url(./Assets/fonts/MaterialIcons-Regular.ttf) format("truetype");
  src: url(./Assets/fonts/MaterialIconsSharp-Regular.otf);
}

@font-face {
  font-family: NafunTechIcons;
  src: url(Assets/fonts/NafunTechIcons.ttf) format('truetype');
}

html {
  box-sizing: border-box;
  padding: 0 !important;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.material-icons {
  font-family: material-icons, serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
}

.custom-map-control-button {
  /*noinspection CssInvalidPropertyValue*/
  appearance: button;
  background-color: #fff;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  margin: 5px 10px;
  height: 40px;
  overflow: hidden;
}

.no_select {
  user-select: none;
}

.map_icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.map_icon.active {
  background-color: #8b8b8b;
  color: white;
}

:root {
  --theme-color: rgb(255, 195, 1);
  --theme-close-color: #ffe198;
  --opposit-theme-color: #fff;
  --adjective-color: #3c3c3c;
  --first-text-color: #fff;
  --second-text-color: #979292;
  --third-text-color: rgb(255, 122, 89);
  --shadow: rgba(0, 0, 0, 0.1);
  --background: #fff;
  --fade: rgba(0, 0, 0, 0.25);
  font-family: iransans, vazir, homa, "system-ui";
  background-color: white;
  --background-color: white;
  padding: 0 !important;
  --box-shadow: 0 0 19px 0 rgb(0 0 0 / 16%);
}

.sortable_tree.rtl {
  direction: rtl;
}

.goog-te-banner-frame.skiptranslate {
  display: none;
}

#google_translate_element {
  display: none;
  visibility: hidden;
  z-index: -1;
}

.CustomScrollbar::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #ffffff;
}

.CustomScrollbar::-webkit-scrollbar-thumb {
  width: 5px;
  height: 5px;
  background-color: #cccccc;
}

body,
html,
.App,
#root {
  height: 100%;
  max-height: 100%;
  /* max-width: initial !important; */
  /* margin: auto; */
  padding: 0 !important;
  /* max-width: calc(100% - .6px); */
  box-sizing: border-box;
  /* display:flex; */
}

body {
  overscroll-behavior:contain;
  margin: 0 !important;
  top: 0 !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% auto;
  background-attachment: fixed;
}

/* side bar */

.rst__rowTitle {
  display: block;
}

.rst__toolbarButton svg {
  width: 1.4em;
  padding: 0 0.2em;
  height: 100%;
}

.pro-icon {
  font-size: 1.7em;
}
.fallback{
  position: absolute;
  text-align: center;
  width:100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background:#f1f5f9;
}
.fallback svg{
  margin:auto;
}

div.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout {
  overflow: unset;
}

div.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout .pro-sidebar-content {
  overflow-y: auto;
}

/* sidebar end */

.skiptranslate {
  display: none !important;
}

/* loginSliderDesktop */

#loginSliderDesktop .swiper-pagination {
  position: relative;
  margin-top: min(8.3vh, 90px);
}

#loginSliderDesktop .swiper-pagination .swiper-pagination-bullet {
  width: 32px;
  height: 0;
  margin: 0 16px 0 0;
  border: 2px solid rgba(0, 0, 0, 0.57);
  background-color: rgba(0, 0, 0, 0.87);
  border-radius: 3px;
  padding: 0;
}

#loginSliderDesktop .swiper-pagination .swiper-pagination-bullet .swiper-pagination-bullet-active {
  opacity: 0.87;
}

/* react-menu */

.App {
  font-size: 1.7em;
  position: relative;
  overflow: auto;
}

@media (max-width: 800px) {
  .chat-btn {
    display: none;
  }

  .notification::after {
    right: 100% !important;
    transform: rotate( 90deg ) !important;
    bottom: calc(50% - 0.75em) !important;
    top: auto !important;
  }
  
  .notification {
    transform: translateY(-25%);
    box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.1);
  }
  
  body.rtl .notification::after {
    right: 100% !important;
    transform: rotate( 90deg ) !important;
  }
  
  body.ltr .notification::after {
      transform: rotate(-90deg) !important;
      right: -24px !important;
  }
}

@media (max-width: 90em) {
  .App {
    font-size: 1.5em;
  }
}

@media (max-width: 78em) {
  .App {
    font-size: 1.4em;
  }
}

@media (max-width: 66em) {
  .App {
    font-size: 1.3em;
  }
}

/* 768px */
@media (max-width: 48em) {
  .App {
    font-size: 1.2em;
  }
}

@media (max-width: 27em) {
  .App {
    font-size: 1em;
  }
}

.Toastify__toast {
  font-family: inherit;
}

.Toastify__toast-body {
  font-family: inherit;
}

body[data-show-crisp="false"] .chat-main {
  display: none !important;
}

/* Color Gradient Picker */
/* ---------------------------------------------------------------------------------- */
.color-hue-alpha {
  margin-left: 0 !important;
}

.preview-area {
  display: none;
}

/* .ui-color-picker{
  width:100%;
  padding:1em;
  box-sizing: border-box;
  border-radius: 5px;
  margin-left:0 !important;
  margin-right:0 !important;
} */

/* ---------------------------------------------------------------------------------- */


/* react-tel-input */
/* ---------------------------------------------------------------------------------- */
.react-tel-input .country-list .search {
  display: flex;
}

.react-tel-input .country-list .search-emoji {
  display: none !important;
}

.react-tel-input .country-list .search-box {
  margin-left: 0 !important;
}

/* ---------------------------------------------------------------------------------- */

/* sweet alert's button and text */

.swal.footer, .swal-text {
  text-align: center;
}
