.dialog{
    position:fixed;
    font-size: 1em;
    width: 25em;
    min-width: 70%;
    max-width:80%;
    min-height:75vh;
    height:20em;
    top:50%;
    left:50%;
    transform: translate(-50% , -50%);
    margin:auto;
    border-radius: 8px;
    background-color: #fff;
    z-index: 1021;
    /*padding:2em;*/
    overflow-y: auto;
}
.dialog > svg{
    cursor: pointer;
}
.formBox{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: right;
    position: relative;
    align-items: flex-end;
    padding:1em;
}
.inputBox{
    width:100%;
    display: flex;
    flex-direction: column;
    margin-top:24px;
}
.inputBox > span{
    white-space: nowrap;
    font-family: iransans ,vazir,homa,'system-ui';
    font-size: .9em;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.87);
}
.inputBox > input{
    border:0;
    border-radius: 8px;
    border: solid 1px rgba(0, 0, 0, 0.12);
    padding:1em 1em;
    margin-top:12px;
    outline: none;
    font-family: inherit;
}
.inputBox > input::placeholder{
    font-family: inherit;
}
.inputBox > select{
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
    border:0;
    border-radius: 8px;
    border: solid 1px rgba(0, 0, 0, 0.12);
    padding:1em 1em;
    margin-top:12px;
    outline: none;
    font-family: inherit;
    background-color: #fff;
}
.Button button{
    width:7em;
    height: 3em;
    background-color: var(--theme-color);
    border:none;
    outline: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 1em;
    font-family: inherit;
}
.overlay{
    position:fixed;
    top:0;
    bottom: 0;
    right:0;
    left:0;
    margin:auto;
    width:100%;
    height:100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1000;
}
.container{
    display:grid;
    grid-template-columns:25% 75%;
    height:100%;
}
.saveBtn{
    padding: 14px 2px;
    margin:1em 0;
    border-radius: 6px;
    background-color:     #15803d;
    width:10em;
    font-size: 1em;
    color: rgba(255, 255, 255, 0.9);


    border:none;
    height:3em;
    align-items: center;
    display:flex;
    justify-content:center;
}
.saveBtn span{
    width:20px !important;
    height:20px !important;
}
.buttonsContainer{
    position: absolute;
    bottom:0;
    display:flex;
    gap: 1em;
}
.cancelBtn{
    background: #ea5656 ;
}
.textContainer{
    background-color: #fef8f8;
    padding:2em;
}
