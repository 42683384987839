/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
  left: 8px;
}

.bm-cross-button:hover .bm-cross {
  background: tomato;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  position: absolute !important;
  height: 100vh !important;
  top: 0;

  /* make it go in front of the crisp support */
  z-index: 10211 !important;
}

/* General sidebar styles */
.bm-menu {
  background: #fff;
  /* padding: 2.5em 1.5em 0; */
  font-size: 1.15em;
  top: 0;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  height: fit-content !important;
  color: #b8b7ad;
  /* padding: 0.8em; */
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  max-width: var(--max);
  z-index: 10000;
  position: absolute !important;
}
