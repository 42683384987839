.container {
  position: fixed;
  inset: 0;
  width: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: -1;
  opacity: 0;
  transition: all 200ms;
}

.container.showModal {
  /* make it go in front of the crisp support */
  z-index: 1212;
  display:flex;
  opacity: 1;
}

.main {
  background: #fff;
  transition: all 200ms;
  z-index: 1213;
  position: relative;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: -1;
  display: none;
}

.backdrop.showBackdrop {
  /* make it go in front of the crisp support */
  z-index: 1211;
  display: block;
}

button.close {
  font-size: 20px;
  position: absolute;
  color: #4e4e4e;
  padding: 14px;
  left: calc(100% - 18px);
  right: calc(100% - 18px);
  transform: translateX(100%);
  top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  border-radius: 100%;
}

.close svg {
  flex-shrink: 0;
}

.close:hover {
  cursor: pointer;
}
