@font-face {
  font-family: homa;
  src: url(../Assets/fonts/homa.ttf) format("truetype");
}

@font-face {
  font-family: yekan;
  src: url(../Assets/fonts/yekan.ttf) format("truetype");
}

@font-face {
  font-family: dastnevis;
  src: url(../Assets/fonts/dastnevis.ttf) format("truetype");
}

@font-face {
  font-family: vazir;
  src: url(../Assets/fonts/vazir-fd.ttf) format("truetype");
}

@font-face {
  font-family: iransans;
  src: url(../Assets/fonts/woff/IRANSansX-Regular.woff) format("woff");
  src: url(../Assets/fonts/woff2/IRANSansX-Regular.woff2) format("woff2");
}

@font-face {
  font-family: material-icons;
  src: url(../Assets/fonts/MaterialIcons-Regular.ttf) format("truetype");
  src: url(../Assets/fonts/MaterialIconsSharp-Regular.otf);
}

@font-face {
  font-family: NafunTechIcons;
  src: url(../Assets/fonts/NafunTechIcons.ttf) format('truetype');
}

:root {
  --chat-gradient-color: #f18e8e;
  --background-color: #ffffff;
  --item-color: #ffffff;
  --theme-color: #DB161B;
  --oposite-theme-color: #ffffff;
  --theme-close-color: #DB161B0D;
  --oposite-theme-close-color: #57b894;
  --first-text-color: #000000db;
  --second-text-color: #ffffff;
  --third-text-color: #f18e8e;
  --fade: #1a1a1a8a;
  --shadow: rgba(0, 0, 0, 0.1);
  --maxWidth: 1600px;
  --toastify-font-family: iransans;
  --layout-padding: 4em;
  --textbar-setting:#fef2f2;
  --button-submit-setting:#ed4c67;
  --button-submit-setting-color:#fff;
}
@media (prefers-color-scheme: dark) {
  body {
    color: inherit !important;
    background: inherit !important;
  }
}
@media (max-width: 959px) {
  :root {
    --layout-padding: 1em;
  }
}

html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
#__next{
  width: 100%;
  height: 100%;
}
a.not_link {
  color: inherit;
  text-decoration: none;
}

.autoSwiper .swiper-slide{
  width: fit-content;
}

* {
  box-sizing: border-box;
}

button, input, textarea, select {
  font-family: inherit;
}

div.map_icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.material-icons {
  font-family: 'material-icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}
.fab{
  position: static;
}
.no_select {
  user-select: none;
}
.custom-map-control-button {
  appearance: button;
  background-color: #fff;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 1px 4px -1px rgb(0 0 0 / 30%);
  cursor: pointer;
  margin: 10px;
  height: 40px;
  overflow: hidden;
}
.custom-map-control-button.active {
  background-color: #1d99bd;
  color: #fff;
}


/* loginSliderDesktop */
#loginSliderDesktop .swiper-pagination {
  position: relative;
  margin-top: min(8.3vh, 90px);
}

#loginSliderDesktop .swiper-pagination .swiper-pagination-bullet {
  width: 32px;
  height: 0;
  margin: 0 16px 0 0;
  border: 2px solid rgba(0, 0, 0, 0.57);
  background-color: rgba(0, 0, 0, 0.87);
  border-radius: 3px;
  padding: 0;
}

#loginSliderDesktop .swiper-pagination .swiper-pagination-bullet .swiper-pagination-bullet-active {
  opacity: 0.87;
}
/*  --------------------------------------------  */


/* CustomScrollbar */
.CustomScrollbar::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #ffffff;
}

.CustomScrollbar::-webkit-scrollbar-thumb {
  width: 5px;
  height: 5px;
  background-color: #cccccc;
}
/* ------------------------------------------------------- */

/* sweet alert buttons */
.swal-footer {
  text-align: center;
}


.ellipsis{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ellipsis2{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ellipsis3{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}


.pro-icon {
  font-size: 1.7em;
}

@supports (-webkit-line-clamp: 1) {
  .ellipsis{
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

@supports (-webkit-line-clamp: 2) {
  .ellipsis2{
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

@supports (-webkit-line-clamp: 3) {
  .ellipsis3{
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}
