.Header {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  background: #fff;
  font-size: 1.1em;
  padding-inline-start: 1em;
  padding-inline-end: 0.4em;
  height: 2.5em;
  padding-bottom: 1.5em;
  padding-top: 1.5em;
  z-index: 100;
  margin: 0 auto;
  width: 100%;
  box-shadow: 1px 1px 5px var(--shadow);
  box-sizing: border-box;
}

.Header .title {
  /*color: white;*/
  min-width: 5em;
  font-weight: bold;
  margin-inline-start: 0.5em;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.Header .search > * {
  padding: 0.5em;
  min-width: 2em;
  width: 2em;
  height: auto;
  box-sizing: border-box;
}
.Header .search {
  font-size: 1.3em;
  min-width: 2em;
  width: 2em;
  height: 2em;
  box-sizing: border-box;
  border-radius: 50%;
}
.Header .mission > * {
  padding: 0.1em;
  box-sizing: border-box;
  display: block;
}
.mission img{
  display: block;
  max-width: 100%;
}
.Header .mission {
  font-size: 1.3em;
  width: 2em;
  min-width: 2em;
  height: 2em;
  border-radius: 50%;
  box-sizing: border-box;
  margin-inline-start: 0.2em;
}
.Header .arrowback > * {
  padding: 0.1em;
}
.Header .arrowback {
  position:relative;
  display:flex;
  display:-webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  font-size: 1.3em;
  height: 2em;
  /* min-width: 2em;
  width: 2em;
  min-height: 2em; */

  box-sizing: border-box;
  border-radius: 50%;
}
.burgerwrapper {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  min-width: 1.8em;
  min-height: 1.8em;
  padding: 0.4em 0.2em;
  cursor: pointer;
  /* transform: rotateY(180deg); */
}

/* .burgerwrapper.rtl {
  transform: rotateY(0deg);
} */

.Header[dir="ltr"] svg {
  transform: scaleX(-1);
}
.Header[dir="ltr"] img {
  transform: scaleX(-1);
}

.cartIcon {
  position: relative;
  font-size: 1.3em;
  min-width: 2em;
  width: 2em;
  height: 2em;
  box-sizing: border-box;
  display: flex !important;
  align-items: center;
  justify-content: center;
  /*border-radius: 100%;*/
}

.cartBadge {
  position: absolute;
  bottom: 4px;
  right: 4px;
  width: 23px;
  height: 23px;
  border-radius: 100%;
  font-size: 14px;
  background-color: var(--theme-color);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--first-text-color);
  cursor: pointer;
  font-weight: bolder;
  font-family: Arial, serif;
}

.shake {
  animation: shakeCart 0.4s ease-in-out forwards;
}

.SideBar{
  position: fixed;
  z-index: 1100;
  width: 100%;
  max-width: var(--max);
  height: 100%;
  pointer-events: none;
  overflow: hidden;
}
.SideBar>div{
  pointer-events: none;
  height: 100%;
}
.SideBar>div>div{
  pointer-events: all;
  height: 100%;
}

.Header .radialBtn > img, .Header .radialBtn > svg {
  padding: 0.1em;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  color: black;
  display: block;
}
.Header .radialBtn {
  font-size: 1.3em;
  min-width: 2em;
  width: 2em;
  height: 2em;
  box-sizing: border-box;
  border-radius: 50%;
  margin: 0 5px;
  color: black;
}

.radialBtn .ltr {
  transform: rotate(180deg);
}

/* .bellIcon {

} */

@media (min-width: 700px) {
  .Header .radialBtn, .Header .cartIcon {
    font-size: 27px;
  }
}

@keyframes shakeCart {
  25% {
    transform: translateX(12px);
  }
  50% {
    transform: translateX(-8px);
  }
  75% {
    transform: translateX(4px);
  }
  100% {
    transform: translateX(0);
  }
}
