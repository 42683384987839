.img {
  display: block;
  margin: auto;
  width: 100%;
  max-width: 300px;
}

.main {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}