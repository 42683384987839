.language {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-inline-end: 0.5em;
  }
  
  .language > div {
    /* width: 9em; */
    display: flex;
    cursor: pointer;
    /* align-items: center; */
    /* border: 1px solid #dfdfdf; */
    /* border-radius: 4px; */
    /* padding: 0.5em 1em; */
  }
  
  /* .language > div > svg {
    width: 1.5em;
    height: 1.5em;
    fill: var(--theme-color);
    margin-inline-start: auto;
  } */
  
  .language > div > span {
    font-size: 1em;
    font-weight: bold;
    margin-inline-end: 0.5em;
  }
  
  .language > div > img {
    width: 38px;
    height: 24px;
    object-fit: cover;
    cursor: pointer;
    margin-inline-end: 0.5em;
    border-radius: 2px;
  }
  
  .SelectBackdrop {
    display: none !important;
    position: fixed;
    width: 100vw !important;
    height: 100vh;
    background-color: transparent;
    top: 0;
    left: 0;
    z-index: 100000;
    cursor: default !important;
  }
  
  .SelectBackdrop.active {
    display: block !important;
  }
  .selectLanguage {
    position: absolute;
    width: 9em;
    top: 120%;
    display: none;
    flex-direction: column;
    background-color: var(--background-color);
    padding: 0;
    border-radius: 2px;
    list-style: none;
    box-shadow: 0 3px 20px 0 var(--shadow);
    margin: 0;
    z-index: 100001;
  }
  
  .selectLanguage.color {
    width: max-content;
  }
  .selectLanguage.active {
    display: flex;
  }
  
  .color.selectLanguage > li {
    justify-content: space-between;
    align-items: center;
  }
  .color.selectLanguage > li > span {
    padding-inline-end: 0.5em;
  }
  .selectLanguage > li {
    display: flex;
    align-items: start;
    padding: 0.8em 0;
    padding-inline-start: 1em;
    padding-inline-end: 1em;
    cursor: pointer;
  }
  
  .selectLanguage > li > img {
    width: 1.5em;
    margin-inline-end: 0.5em;
    border-radius: 2px;
  }
  
  .selectLanguage > li:hover {
    background-color: #dfdfdf;
    transition: color 0.2s;
  }
